import { EditOutlined, UploadOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Form,
  Image,
  InputNumber,
  Modal,
  Select,
  Space,
  Table,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useState } from "react";
import useSearch from "../components/common/useSearch2";
import {
  getRefferal,
  getUsers,
  updateKyc,
  updateUser,
  userReferral,
  userSerach,
} from "../utils/api";
import { useAuth } from "../utils/store";

const Users = () => {
  const [page, setPage] = useState(1);

  const [searchData, setSearchData] = useState({});
  const search = useSearch(
    () => {
      setPage(1);
      setSearchData(null);
    },
    (data) => {
      setSearchData(data);
    }
  );
  const user = useAuth((state) => state.user);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["users", page, searchData],
    queryFn: user?.getAllUsers
      ? async () =>
          searchData?.searchQuery
            ? await userSerach(searchData)
            : await getUsers(page)
      : [],
  });

  return (
    <div>
      <Typography.Title level={2}>Users</Typography.Title>
      {user?.createReferral && <AddUserToReferal />}

      <Table
        loading={isLoading}
        pagination={{
          pageSize: 20,
          total: data?.pages * 20,
          current: page,
          onChange: (page) => setPage(page),
        }}
        scroll={{ x: 1000 }}
        dataSource={user?.getAllUsers ? data?.users : []}
        columns={[
          {
            dataIndex: "Actions",
            title: "Actions",
            render: (value, record) => (
              <UpdateKyc user={record} refetch={refetch} />
            ),
          },
          {
            dataIndex: "image",
            title: "Image",
            render: (value) => <Image src={value} width={100} height={100} />,
          },
          {
            dataIndex: "name",
            title: "Name",
            ...search("name"),
          },
          {
            dataIndex: "phoneNumber",
            title: "Phone",
            ...search("phoneNumber"),
          },
          {
            dataIndex: "email",
            title: "Email",
            ...search("email"),
          },
          {
            dataIndex: "wallet",
            title: "Wallet",
            render: (value, record) => (
              <Space size="middle">
                <span>{value}</span>
                {user?.updateUser && (
                  <EditWallet user={record} refetch={refetch} />
                )}
              </Space>
            ),
          },
          {
            dataIndex: "walletHistory",
            title: "Wallet History",
            render: (value) => (
              <Button
                type="primary"
                onClick={() => {
                  Modal.info({
                    title: "History",
                    content: (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        {value.map((item) => (
                          <p>{item}</p>
                        ))}
                      </div>
                    ),
                  });
                }}
              >
                Wallet History
              </Button>
            ),
          },
          {
            dataIndex: "referredHistory",
            title: "Referred History",
            render: (value) => (
              <Button
                type="primary"
                onClick={() => {
                  Modal.info({
                    title: "History",
                    content: (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        {value.map((item) => (
                          <p>{item}</p>
                        ))}
                      </div>
                    ),
                  });
                }}
              >
                Ref History
              </Button>
            ),
          },
          {
            dataIndex: "isSubscriptionValid",
            title: "Is Subscription Valid",
            render: (value) => (value ? "Yes" : "No"),
          },
          {
            dataIndex: "aadharcardImage",
            title: "Aadhar card Image",
            render: (value) => <Image src={value} width={100} height={100} />,
          },
          {
            dataIndex: "pancardImage",
            title: "Pan card Image",
            render: (value) => <Image src={value} width={100} height={100} />,
          },
          {
            dataIndex: "aadharcardNumber",
            title: "Aadhar Card Number",
          },
          {
            dataIndex: "pancardNumber",
            title: "Pan Card Number",
          },
          {
            dataIndex: "_id",
            title: "Referral Chain",
            render: (value) => <ReferralChain value={value} />,
          },
        ]}
      />
    </div>
  );
};

export default Users;

const UpdateKyc = ({ user, refetch }) => {
  const [open, setOpen] = useState(false);
  const { mutate, isPending } = useMutation({
    mutationFn: (values) => updateKyc(values, user?._id),
    onSuccess: (values) => {
      message.success("Wallet Updated");
      refetch();
      setOpen(false);
    },
  });

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        icon={<EditOutlined />}
      >
        Update KYC
      </Button>

      <Modal
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        title="Edit Kyc"
        footer={null}
      >
        <Form
          onFinish={(values) => {
            const formData = new FormData();

            formData.append("aadharcardNumber", values.aadharcardNumber);
            formData.append("pancardNumber", values.pancardNumber);
            if (values.aadharcardImage) {
              formData.append(
                "aadharcardImage",
                values.aadharcardImage.file.originFileObj
              );
            }
            if (values.pancardImage) {
              formData.append(
                "pancardImage",
                values.pancardImage.file.originFileObj
              );
            }
            formData.append("userId", user?._id);
            mutate(formData);
          }}
          labelCol={{
            span: 8,
          }}
          // defaultValue={{
          //   wallet: user?.wallet,
          // }}

          initialValues={{
            aadharcardNumber: user?.aadharcardNumber,
            pancardNumber: user?.pancardNumber,
          }}
        >
          <Form.Item
            label="Aadhar Card Number"
            name="aadharcardNumber"
            style={{
              width: "100%",
            }}
          >
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Pan Card Number"
            name="pancardNumber"
            rules={[
              {
                message: "Please input !",
              },
            ]}
          >
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Aadhar Card Image"
            name="aadharcardImage"
            style={{
              width: "100%",
            }}
          >
            <Upload>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Pan Card Image"
            name="pancardImage"
            style={{
              width: "100%",
            }}
          >
            <Upload>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Button htmlType="submit" loading={isPending}>
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
};
const EditWallet = ({ user, refetch }) => {
  const [open, setOpen] = useState(false);
  const { mutate, isPending } = useMutation({
    mutationFn: (values) => updateUser(values, user?._id),
    onSuccess: (values) => {
      message.success("Wallet Updated");
      refetch();
      setOpen(false);
    },
  });

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        icon={<EditOutlined />}
      ></Button>

      <Modal
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        title="Edit Wallet"
        footer={null}
      >
        <Form
          onFinish={(values) => {
            mutate({
              wallet: values.wallet,
            });
          }}
          // defaultValue={{
          //   wallet: user?.wallet,
          // }}

          initialValues={{
            wallet: user?.wallet,
          }}
        >
          <Form.Item
            label="Wallet"
            name="wallet"
            rules={[
              {
                required: true,
                message: "Please input your wallet!",
              },
            ]}
            style={{
              width: "100%",
            }}
          >
            <InputNumber />
          </Form.Item>
          <Button htmlType="submit" loading={isPending}>
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
};

const ReferralChain = ({ value }) => {
  const [fetch, setFetch] = useState(false);
  const [open, setOpen] = useState(false);
  const { data, isLoading } = useQuery({
    queryKey: ["referral-chain", value],
    queryFn: () => getRefferal(value),
    enabled: fetch,
  });

  return (
    <>
      <Button
        type="primary"
        loading={isLoading}
        onClick={() => {
          setFetch(true);
          setOpen(true);
        }}
      >
        Chain
      </Button>
      <Modal
        confirmLoading={isLoading}
        open={open}
        onCancel={() => {
          setOpen(false);
          setFetch(false);
        }}
        footer={null}
        title="Chain"
        width={800}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: data,
          }}
        />
      </Modal>
    </>
  );
};

const AddUserToReferal = () => {
  const [open, setOpen] = useState(false);

  const { mutate: mutate2, isPending: isPending2 } = useMutation({
    mutationFn: (data) => userReferral(data),
    onSuccess: (data) => {
      message.success("User Ref added");
    },
    onError: (data) => {
      message.error(data?.response?.data?.message ?? "");
    },
  });

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setOpen(true);
        }}
        style={{
          marginBottom: "30px",
        }}
      >
        Add referral
      </Button>
      <Modal
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        title="Add User to Referral"
        footer={null}
      >
        <Form
          onFinish={(values) => {
            mutate2(values);
          }}
        >
          <Form.Item label="Parent" name="parentPhoneNumber">
            <SelectSearch />
          </Form.Item>
          <Form.Item label="child" name="childPhoneNumber">
            <SelectSearch />
          </Form.Item>
          <Button htmlType="submit" loading={isPending2}>
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
};

const SelectSearch = ({ value, onChange }) => {
  const [options, setOptions] = useState([]);
  const { mutate, isPending } = useMutation({
    mutationFn: (res) =>
      userSerach({
        limit: 20,
        page: 1,
        searchType: "Name",
        searchQuery: res ?? "",
      }),
    onSuccess: (data) => {
      setOptions(data.users);
    },
  });

  return (
    <Select
      value={value}
      onChange={onChange}
      onFocus={() => mutate("")}
      onSearch={(value) => {
        mutate(value);
      }}
      options={options.map((item) => ({
        value: item.phoneNumber,
        label: item.name,
      }))}
      loading={isPending}
      showSearch
      filterOption={(value) => value}
    />
  );
};
