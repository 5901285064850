import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Space,
  Statistic,
  Typography,
  message,
} from "antd";
import React, { useEffect } from "react";
import { dashboard, getSystemValues, updateSystemValues } from "../utils/api";
import { useAuth } from "../utils/store";

import { OrderedListOutlined, UserOutlined } from "@ant-design/icons";

const Dashboard = () => {
  const user = useAuth((state) => state.user);

  const { data } = useQuery({
    queryKey: ["system-values"],
    queryFn: user?.getSystemValues ? getSystemValues : [],
  });

  const { data: dash } = useQuery({
    queryKey: ["dashboard"],
    queryFn: dashboard,
  });

  const values = [
    "stage1",
    "stage2",
    "stage3",
    "stage4",
    "stage5",
    "stage6",
    "stage7",
    "stage8",
    "stage9",
    "stage10",
    "redeemAmount",
    "paymentValue",
  ];

  const [form] = Form.useForm();

  const { mutate, isPending } = useMutation({
    mutationFn: user?.updateSystemValues ? updateSystemValues : [],
    onSuccess: () => {
      message.success("System values updated successfully");
    },
  });

  useEffect(() => {
    form.setFieldsValue({
      stage1: data?.stage1,
      stage2: data?.stage2,
      stage3: data?.stage3,
      stage4: data?.stage4,
      stage5: data?.stage5,
      stage6: data?.stage6,
      stage7: data?.stage7,
      stage8: data?.stage8,
      stage9: data?.stage9,
      stage10: data?.stage10,
      redeemAmount: data?.redeemAmount,
      paymentValue: data?.paymentValue,
    });
  }, [data, form]);
  return (
    <Space size={"large"} direction="vertical">
      <Typography.Title>Dashboard</Typography.Title>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card title="Total Referral">
            <Statistic
              value={dash?.totalReferral}
              prefix={<OrderedListOutlined />}
            />
          </Card>
        </Col>{" "}
        <Col span={12}>
          <Card title="Total Users">
            <Statistic value={dash?.totalUsers} prefix={<UserOutlined />} />
          </Card>
        </Col>
      </Row>
      {user?.updateSystemValues && (
        <Form
          form={form}
          onFinish={(values) => {
            mutate(values);
          }}
        >
          <Row gutter={[16, 16]}>
            {values.map((value) => (
              <Col span={8} key={value}>
                <Form.Item
                  label={value
                    .replace("stage", "Level ")
                    .replace("redeemAmount", "Redeem Amount")
                    .toUpperCase()}
                  name={value}
                  rules={[
                    {
                      required: true,
                      message: "Please input the system value!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            ))}
          </Row>
          <Button loading={isPending} htmlType="submit">
            Save
          </Button>
        </Form>
      )}
    </Space>
  );
};

export default Dashboard;
