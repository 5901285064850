import "./App.css";
import { ConfigProvider, theme } from "antd";
import { Route, Routes, useNavigate } from "react-router-dom";
import Layouts from "./components/common/Layout";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import Admin from "./pages/Admin";
import { useAuth } from "./utils/store";
import React from "react";
import LoginForm from "./pages/Login";
import ReedeemRequest from "./pages/ReedeemRequest";

function App() {
  const user = useAuth((state) => state.user);
  const router = useNavigate();
  React.useEffect(() => {
    if (!user) {
      router("/login");
    }
  }, [user, router]);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#ff0000",
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <Routes>
        <Route
          path="/"
          element={
            <Layouts>
              <Dashboard />
            </Layouts>
          }
        />
        <Route
          path="/users"
          element={
            <Layouts>
              <Users />
            </Layouts>
          }
        />
        <Route
          path="/redeem-request"
          element={
            <Layouts>
              <ReedeemRequest />
            </Layouts>
          }
        />
        <Route path="/login" element={<LoginForm />} />
        <Route
          path="/admins"
          element={
            <Layouts>
              <Admin />
            </Layouts>
          }
        />
      </Routes>
    </ConfigProvider>
  );
}

export default App;
