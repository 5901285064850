import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Input, Modal, Space, Table, Tabs, message } from "antd";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { getAllRedeemRequest, redeemRequest } from "../utils/api";
import { useAuth } from "../utils/store";
const ReedeemRequest = () => {
  const { data, refetch } = useQuery({
    queryKey: ["redeem-request"],
    queryFn: getAllRedeemRequest,
  });
  const [tab, setTab] = useState("1");
  const user = useAuth((state) => state.user);
  const column = [
    {
      title: "User",
      dataIndex: ["user", "name"],
      key: "user",
    },
    {
      title: "Wallet",
      dataIndex: ["user", "wallet"],
      key: "wallet",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Bank Details",
      dataIndex: "bankDetails",
      key: "bankDetails",
      render: (value, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              Modal.info({
                title: "Bank Details",
                content: (
                  <div>
                    <p>
                      Bank Details Added:{" "}
                      {record?.user?.isBankDetailAdded ? (
                        <CheckOutlined style={{ color: "green" }} />
                      ) : (
                        <CloseOutlined style={{ color: "red" }} />
                      )}
                    </p>
                    {record?.user?.accountNumber && (
                      <p>Account Number: {record?.user?.accountNumber}</p>
                    )}
                    {record?.user?.bankName && (
                      <p>Bank Name: {record?.user?.bankName}</p>
                    )}
                    {record?.user?.branchName && (
                      <p>Branch Name: {record?.user?.branchName}</p>
                    )}
                    {record?.user?.ifscCode && (
                      <p>IFSC: {record?.user?.ifscCode}</p>
                    )}
                  </div>
                ),
              });
            }}
          >
            View
          </Button>
        );
      },
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   render: (value, record) => {
    //     return (
    //       <Space size="middle">
    //         <ApproveAndReject
    //           type={"Completed"}
    //           id={record}
    //           refetch={refetch}
    //         />
    //         <ApproveAndReject
    //           type={"Decline"}
    //           id={record}
    //           refetch={refetch}
    //         />
    //       </Space>
    //     );
    //   },
    // },
  ];

  const res =
    tab === "1" ? data?.created : tab === "2" ? data?.completed : data?.decline;
  return (
    <main>
      {user?.redeemReqest && (
        <>
          <CSVLink
            data={
              res?.map((item) => {
                return {
                  User: item?.user?.name,
                  Wallet: item?.user?.wallet,
                  Status: item?.status,
                  BankDetails: item?.bankDetails,
                  AccountNumber: item?.user?.accountNumber,
                  BankName: item?.user?.bankName,
                  BranchName: item?.user?.branchName,
                  IFSC: item?.user?.ifscCode,
                };
              }) ?? []
            }
            filename="redeem-request.csv"
            target="_blank"
          >
            <Button onClick={() => refetch()}>Export CSV</Button>
          </CSVLink>
          <Tabs
            onTabClick={(key) => setTab(key)}
            defaultActiveKey="1"
            items={[
              {
                label: "Created",
                key: "1",
                children: (
                  <Table
                    dataSource={data?.created ?? []}
                    columns={[
                      ...column,
                      {
                        title: "Action",
                        dataIndex: "action",
                        key: "action",
                        render: (value, record) => {
                          return (
                            <Space size="middle">
                              <ApproveAndReject
                                type={"Completed"}
                                id={record}
                                refetch={refetch}
                              />
                              <ApproveAndReject
                                type={"Decline"}
                                id={record}
                                refetch={refetch}
                              />
                            </Space>
                          );
                        },
                      },
                    ]}
                  />
                ),
              },
              {
                label: "Completed",
                key: "2",
                children: (
                  <Table dataSource={data?.completed ?? []} columns={column} />
                ),
              },
              {
                label: "Decline",
                key: "3",
                children: (
                  <Table dataSource={data?.decline ?? []} columns={column} />
                ),
              },
            ]}
          />
        </>
      )}
    </main>
  );
};

export default ReedeemRequest;

const ApproveAndReject = ({ id, type, refetch }) => {
  const [message1, setMessage1] = useState("");
  const [open, setOpen] = useState(false);

  const { mutate, isPending } = useMutation({
    mutationFn: (values) =>
      redeemRequest(id._id, {
        status: type,
        userId: id?.user._id,
        message: message1,
      }),
    onSuccess: () => {
      message.success(`${type} successfully`);
      refetch();
    },
  });

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setOpen(true);
        }}
        style={{
          background: type === "Completed" ? "green" : "red",
        }}
        loading={isPending}
      >
        {type}
      </Button>
      <Modal
        title={type}
        open={open}
        onOk={() => {
          mutate();
          setOpen(false);
        }}
        onCancel={() => setOpen(false)}
      >
        <Input.TextArea
          value={message1}
          onChange={(e) => setMessage1(e.target.value)}
          placeholder="Message"
        />
      </Modal>
    </>
  );
};
