import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { Button, Card, Form, Image, Input, Typography, message } from "antd";
import { useNavigate } from "react-router-dom";
import { login } from "../utils/api";
import { useAuth } from "../utils/store";

const LoginForm = () => {
  const router = useNavigate();
  const setUser = useAuth((state) => state.setUser);
  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: async (values) => await login(values),
    onSuccess: (data) => {
      setUser(data);
      message.success(`Welcome ${data?.email}`);
      router("/");
    },
    onError: (error) => {
      message.error(error?.response?.data?.message);
    },
  });

  const onFinish = (values) => {
    mutate(values);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#E4F1FF",
      }}
    >
      <Card style={{ width: 350 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 20,
          }}
        >
          <Image src="/logo.png" preview={false} height={80} />
        </div>
        <Form
          disabled={isLoading}
          name="normal_login"
          className="login-form"
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your Username!" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              block
              loading={isLoading}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default LoginForm;
