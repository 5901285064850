import axios from "axios";

export const URL = "https://woworiginals.in";

export const getSystemValues = async () => {
  const response = await axios.get(`${URL}/system-values`);
  return response.data;
};

export const updateSystemValues = async (values) => {
  const response = await axios.put(`${URL}/admin/system-values`, values);
  return response.data;
};

export const getUsers = async (page, limit) => {
  const response = await axios.get(`${URL}/admin/users?page=${page}&limit=20`);
  return response.data;
};
export const updateUser = async (data, id) => {
  const response = await axios.put(`${URL}/admin/user/${id}`, data);
  return response.data;
};
export const updateKyc = async (data) => {
  const response = await axios.put(`${URL}/admin/user-kyc`, data);
  return response.data;
};

export const getRefferal = async (id) => {
  const response = await axios.get(`${URL}/chain/${id}`);
  return response.data;
};
export const userSerach = async (data) => {
  const response = await axios.post(`${URL}/admin/user-search`, data);
  return response.data;
};
export const userReferral = async (data) => {
  const response = await axios.post(`${URL}/admin/referral`, data);
  return response.data;
};
export const dashboard = async () => {
  const response = await axios.get(`${URL}/admin/referral-dashboard`);
  return response.data;
};

export const addAdmin = async (data2) => {
  const { data } = await axios.post(`${URL}/admin/signup-referral`, data2);
  return data;
};
export const getAllAdmins = async () => {
  const { data: res } = await axios.get(`${URL}/admin/all-admins`);
  return res;
};
export const getAllRedeemRequest = async () => {
  const { data: res } = await axios.get(`${URL}/admin/redeem-requests`);
  return res;
};
export const login = async (data) => {
  const { data: res } = await axios.post(`${URL}/admin/login-referral`, data);
  return res;
};

export const redeemRequest = async (id, data) => {
  const { data: res } = await axios.put(
    `${URL}/admin/redeem-request/${id}`,
    data
  );
  return res;
};

export const deleteAdmin = async (id) => {
  const { data: res } = await axios.delete(
    `${URL}/admin/delete-referral-admin/${id}`
  );
  return res;
};

export const updateAdmin = async (data, id) => {
  const response = await axios.put(
    `${URL}/admin/referral-admin-update/${id}`,
    data
  );
  return response.data;
};
